import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { Image } from "../../../atoms/Image"
import {
  getSeriesMpgDisclaimerCode,
  normalizeSeriesNameForLink,
  parseDisclaimerBlocks,
  toUsd,
  vehicleCategory,
} from "../../../../helpers"
import { Button } from "../../../atoms/Button"
import { Link } from "../../../atoms/Link"
import Icon from "../../../atoms/Icon"
import { OfferCard } from "../../../molecules/OfferCard"
import {
  VehicleOffersGroupProps,
  FeaturedOffersTabsProps,
} from "./VehicleOffersGroup.d"
import { LocalInventoryLinks } from "../../../molecules/LocalInventoryLinks"
import { LocationContext } from "../../../../contexts/Location"
import { InventoryClient } from "../../../../clients/InventoryClient"
import { css } from "@emotion/react"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { generateInventoryLink } from "../../../../helpers"
import { ThreeArrowAnimation } from "../../../molecules/ThreeArrowAnimation"
import { LanguageContext } from "../../../../contexts/Language"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { useElectricPluginSeriesNames } from "../../../../hooks/useElectricPluginSeriesNames"
import { useTealiumContext } from "../../../../contexts/Tealium"

/**
 * Section on /offers/ page that contains series details and 2 offers.
 * @author Tyler
 * @param {IGatsbyImageData} image Series jellybean image
 * @param {string} name Series name
 * @param {number} msrp Series base MSRP
 * @param {number} mpgCity City MPG
 * @param {number} mpgHighway Highway MPG
 * @param {Object} offers Offers rendered in this section
 * @param {Object} filtered Filtered state, needed to ensure offers re-render correctly
 * @param {number} activeFamily Family tab state, needed to ensure offers re-render correctly
 * @param {Object} seriesYears Most recent 2 series years
 * @returns {React.FC} <VehicleOffersGroup />
 */

const VehicleOffersGroup: React.FC<VehicleOffersGroupProps> = ({
  image,
  name,
  slug,
  msrp,
  mpgCity,
  mpgHighway,
  offers,
  filtered,
  activeFamily,
  seriesYears,
  index,
  selectedTabTitle,
}) => {
  if (!name || !image || !msrp) return null
  const { _, language } = useContext(LanguageContext)
  const [{ city, dealers }] = useContext(LocationContext)
  const [inventoryCount, setInventoryCount] = useState<number | "...">(0)
  const [isLoaded, setIsLoaded] = useState(false)
  const [{}, dispatch] = useContext(DisclaimersContext)

  const seriesYear =
    Array.isArray(seriesYears) &&
    seriesYears[seriesYears?.length - 1]?.toString()
  const mpgDisclaimerCode = getSeriesMpgDisclaimerCode(name, seriesYear)

  const pluginSeriesNames = useElectricPluginSeriesNames()
  const isElectricPluginSeries = pluginSeriesNames.includes(
    name?.toLocaleLowerCase()
  )

  const offerSeriesSlug = normalizeSeriesNameForLink(name)

  let seriesPath = normalizeSeriesNameForLink(name)
  if (name?.toLowerCase()?.includes("hybrid")) {
    seriesPath += "?hybrid=true"
  }

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  useEffect(() => {
    const dealerIds = dealers?.map(dealer => dealer?.DealerCode).join(",")
    const getCount = async () => {
      if (dealerIds?.length > 0) {
        // this was running before the dealers were loaded from context so it was showing like 6k vehicles
        try {
          setInventoryCount("...")
          const { count } = await InventoryClient.getCount(name, {
            dealer: dealerIds,
          })
          setInventoryCount(count)
        } catch (error) {
          setInventoryCount(error)
        }
      }
    }
    getCount()
  }, [activeFamily, dealers, filtered])

  return (
    isLoaded && (
      <article
        css={[
          tw`flex flex-col items-center justify-between gap-7 max-w-7xl w-auto py-20 relative`,
          tw`before:(absolute top-0 content-[""] bg-gray-300 h-px w-4/5 xl:w-full)`,
          tw`xl:( flex-row w-full pt-14 max-h-[700px])`,
          "background: radial-gradient(farthest-side, #dee2e6 0%, #fff 90%);",
        ]}
      >
        {/* SERIES INFO SECTION */}
        <section css={[tw`flex flex-col`]}>
          <div css={[tw`px-8`, tw`lg:(mb-4 px-0)`]}>
            <p css={[tw`text-3xl uppercase font-semibold tracking-widest`]}>
              {name}
            </p>
            <Link
              animated
              animatedThin
              to={`/${seriesPath}`}
              css={tw`mb-2 text-xs`}
            >
              {_("View Models")}{" "}
              <Icon.Chevron
                color="red-400"
                css={[tw`w-2 ml-0.5 mb-0.5 inline-block`]}
              />
            </Link>
          </div>
          <Image
            imageData={image}
            alt={name}
            css={[tw`max-w-[400px] max-h-[130px]`]}
            imgStyle={{ height: "130px" }}
          />
          <div css={[tw`flex justify-center gap-6 text-center mb-8`]}>
            <span>
              <div css={[tw`text-2xl`]}>
                <span>$</span>
                {toUsd(msrp)}
                {parseDisclaimerBlocks(`[starting_msrp]`, code =>
                  dispatch(toggleDisclaimersModal(code))
                )}
              </div>
              <div
                css={[
                  tw`italic font-light text-gray-700`,
                  "font-size: 0.625rem",
                ]}
              >
                {_("Starting MSRP")}
              </div>
            </span>
            <span>
              <div css={[tw`text-2xl`]}>
                <span>{mpgCity}</span> / <span>{mpgHighway}</span>
                {parseDisclaimerBlocks(mpgDisclaimerCode, code =>
                  dispatch(toggleDisclaimersModal(code))
                )}
              </div>
              <div
                css={[
                  tw`italic font-light text-gray-700`,
                  "font-size: 0.625rem",
                ]}
              >
                {isElectricPluginSeries ? _("est. mpg/mpge") : _("est. mpg")}
              </div>
            </span>
          </div>
          <div css={[tw`flex flex-wrap justify-center gap-3`]}>
            <LocalInventoryLinks
              city={city}
              dealerCount={dealers?.length}
              inventoryCount={inventoryCount}
              series={name}
              year={seriesYear}
              inventoryLink={generateInventoryLink({
                series: slug?.toLowerCase(),
              })}
              analyticsId={`series offers:${index + 1}`}
            />
          </div>
        </section>

        {/* OFFERS */}
        <FeaturedOffersTabs
          offers={offers}
          filtered={filtered}
          activeFamily={activeFamily}
          index={index}
          name={name}
          selectedTabTitle={selectedTabTitle}
        />

        {/* AVAILABLE OFFERS AND LINK TO OFFERS SERIES TEMPLATE */}
        <section
          css={[tw`text-center mr-6 ml-6 mt-4 w-auto`, tw`xl:(mt-0 w-auto)`]}
        >
          <div css={[tw`text-sm mb-3`]}>
            <span css={[tw`font-semibold`]}>{offers.availableOffers}</span>{" "}
            {offers.availableOffers > 1 || offers.availableOffers === 0
              ? _("Offers Available")
              : _("Offer Available")}
          </div>
          <Link
            className="group"
            to={`/offers/${offerSeriesSlug}/`}
            css={[
              tw`flex justify-center items-center text-xl font-semibold`,
              tw`focus-visible:(outline-gray)`,
              "line-height: 1.5rem",
            ]}
            onClick={() =>
              trackTealEvent({
                tealium_event: "cta_click",
                coupon_module_text: `View All Offers ${name}`,
                link_href: `/offers/${offerSeriesSlug}/`,
                vehicle_model: name,
                vehicle_model_truncated: name,
              })
            }
            analytics-id={`all offers:series offers:${index + 1}`}
          >
            <div>
              {language === "en" ? (
                <>
                  <div css={[tw`whitespace-nowrap`]}>{_("View All")}</div>
                  <div css={[tw`max-w-[150px] min-w-[150px]`]}>
                    {name}
                    <span> {_("Offers")}</span>
                  </div>
                </>
              ) : (
                <>
                  <div css={[tw`max-w-[250px] min-w-[250px]`]}>
                    {_("View All Offers")} <span> {name}</span>
                  </div>
                </>
              )}
            </div>
            <ThreeArrowAnimation large />
          </Link>
        </section>
      </article>
    )
  )
}

/**
 * Tabs section with selectable series years and the 2 featured offers in each tab
 * @author Tyler
 * @param {Object} offers Contans newestOffers array and secondNewestOffers array
 * @param {Object} filtered Filtered state, needed to ensure offers re-render correctly
 * @param {number} activeFamily Family tab state, needed to ensure offers re-render correctly
 * @returns {React.FC} <FeaturedOffersTabs />
 */

const FeaturedOffersTabs: React.FC<FeaturedOffersTabsProps> = ({
  offers,
  filtered,
  activeFamily,
  index,
  name,
  selectedTabTitle,
}) => {
  const { language, _ } = useContext(LanguageContext)
  // Check to ensure at least one item in each array is NOT undefined
  const checkNewest = offers?.newest?.some((node: any) => node !== undefined)
  const checkSecondNewest = offers?.secondNewest?.some(
    (node: any) => node !== undefined
  )
  const [filteredOffers, setFilteredOffers] = useState([])

  // State to control 2022/2021 tabs
  const [activeTab, setActiveTab] = useState(null)

  // IMPORTANT: Filtered and activeFamily state is prop drilled to here to ensure offers are displayed properly on re-render
  useEffect(() => {
    if (activeTab === null) return

    if (activeTab === offers.newestYear && checkNewest) {
      setFilteredOffers(offers?.newest)
    } else if (activeTab === offers.secondNewestYear && checkSecondNewest) {
      setFilteredOffers(offers?.secondNewest)
    } else {
      setFilteredOffers([])
    }
  }, [offers, activeFamily, activeTab])

  useEffect(() => {
    if (offers?.newest?.length > 0 && checkNewest) {
      setActiveTab(offers?.newestYear)
      setFilteredOffers(offers?.newest)
    } else if (offers?.secondNewest?.length > 0 && checkSecondNewest) {
      setActiveTab(offers?.secondNewestYear)
      setFilteredOffers(offers?.secondNewest)
    }
  }, [])

  // Tab links stay underlined when active
  const activeStyle = tw`opacity-100 font-semibold border-b-2 border-red-400`
  css`
    &:after {
      transform: scaleX(1);
    }
  `

  //  Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()

  return (
    <article
      css={[
        tw`flex flex-col justify-start self-center w-full`,
        tw`lg:(w-full self-start)`,
      ]}
    >
      {/* Offer tab buttons */}
      {activeTab !== null && (
        <section
          css={[
            tw`flex justify-center absolute top-5 left-1/2 -translate-x-1/2`,
            tw`lg:(static translate-x-0)`,
          ]}
        >
          {/* Newest */}
          <button
            // disabled={!checkSecondNewest}
            onClick={() => {
              setActiveTab(offers?.newestYear)
              trackTealEvent({
                tealium_event: "refinement",
                refinement_value: `${tealPageData?.page_type}|${tealPageData?.page_name}|Year|${offers?.newestYear}`,
              })
            }}
            css={[
              tw`cursor-pointer font-semibold opacity-50 mx-8`,
              activeTab === offers?.newestYear && activeStyle,
            ]}
            analytics-id={`year filter:series offers:1`}
          >
            {offers?.newestYear}
          </button>
          {/* Second Newest */}
          <button
            // disabled={!checkNewest}
            onClick={() => {
              setActiveTab(offers?.secondNewestYear)
              trackTealEvent({
                tealium_event: "refinement",
                refinement_value: `${tealPageData?.page_type}|${tealPageData?.page_name}|Year|${offers?.secondNewestYear}`,
              })
            }}
            css={[
              tw`cursor-pointer font-semibold opacity-50 mx-8`,
              tw`focus-visible:(outline-gray)`,
              activeTab === offers?.secondNewestYear && activeStyle,
            ]}
            analytics-id={`year filter:series offers:2`}
          >
            {offers?.secondNewestYear}
          </button>
        </section>
      )}
      {/* Offer tabs */}
      <section
        css={[
          tw`flex items-center min-h-[132px] pt-8 w-full overflow-x-scroll scrollbar-hide`,
          tw`sm:(justify-center)`,
          tw`md:(justify-start)`,
          tw`lg:(min-h-[300px])`,
        ]}
      >
        {filteredOffers?.length > 0 ? (
          <section
            css={[
              tw`flex gap-5 px-4 w-[100vw] overflow-scroll scrollbar-hide`,
              tw`md:(w-auto)`,
              // If displaying only 1 offer, center it on screen
              filteredOffers.length === 1 && tw`w-full justify-center`,
              filteredOffers?.length === 3 &&
                tw`md:(flex-wrap justify-center flex-row) lg:(justify-start)`,
            ]}
          >
            {filteredOffers?.map(
              (node: any, i: number) =>
                node &&
                i < 2 && (
                  <OfferCard
                    offer={node}
                    cardType={node?.cardLabel}
                    cardLabel={
                      language === "es" ? node?.cardLabelES : node?.cardLabel
                    }
                    css={[tw`w-72 shadow-3`]}
                    analyticsId={`offer details:${index + 1}:${i + 1}`}
                  />
                )
            )}
          </section>
        ) : (
          <div css={[tw`w-full text-center px-4`]}>
            {_(`No `)} {selectedTabTitle ? selectedTabTitle : _("Offers")}{" "}
            {_(`found for this`)} {name}, {_(`check back later.`)}
          </div>
        )}
      </section>
    </article>
  )
}

export default VehicleOffersGroup
