import React from "react"
import tw from "twin.macro"
import LoadingOfferCard from "../../../molecules/OfferCard/LoadingOfferCard"

interface OffersLoadingSkeletonProps {
  numVehicles: number
  showFamilySelector?: boolean
}

const OffersLoadingSkeleton: React.FC<OffersLoadingSkeletonProps> = ({
  numVehicles,
  showFamilySelector = false,
}) => {
  return (
    <div
      css={[
        tw`mx-auto w-full max-w-[70%] pt-11 pb-4 lg:(max-w-[50%]) xl:(max-w-7xl) animate-pulse`,
      ]}
    >
      {showFamilySelector && (
        <>
          <div css={[tw`flex justify-between mb-8 gap-x-10 max-w-3xl mx-auto`]}>
            {Array.from({ length: numVehicles }).map((_, index) => (
              <div key={index} css={[tw`w-1/4 space-y-2`]}>
                <div css={[tw` h-20 bg-gray-300 rounded`]}></div>
                <div css={[tw`space-y-1`]}>
                  <div css={[tw`h-4 mx-auto w-1/2 bg-gray-300 rounded`]}></div>
                  <div css={[tw`h-4 mx-auto w-1/3 bg-gray-300 rounded`]}></div>
                  <div css={[tw`h-2 mx-auto w-1/2 bg-gray-300 rounded`]}></div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <div css={[tw`w-full border-b mt-10`]}>
        <div css={[tw`h-6 my-5 w-32 bg-gray-300 rounded animate-pulse`]}></div>
      </div>

      <div css={[tw`flex flex-col justify-between mb-8 gap-10 mx-auto mt-10`]}>
        {Array.from({ length: numVehicles }).map((_, index) => (
          <>
            <div key={index} css={[tw`w-full flex gap-x-7 flex`]}>
              <div css={[tw`w-2/5 flex flex-col gap-5`]}>
                <div css={[tw`flex flex-col gap-2`]}>
                  <div css={[tw`w-36 h-10 bg-gray-300`]}></div>
                  <div css={[tw`w-24 h-3 bg-gray-300`]}></div>
                </div>
                <div css={[tw`flex flex-col gap-2`]}>
                  <div css={[tw`h-28 bg-gray-300 rounded`]}></div>
                  <div css={[tw`flex gap-5 mx-auto`]}>
                    <div
                      css={[tw`h-12 w-20 mx-auto bg-gray-300 rounded`]}
                    ></div>
                    <div
                      css={[tw`h-12 w-28 mx-auto bg-gray-300 rounded`]}
                    ></div>
                  </div>
                </div>
                <div>
                  <div css={[tw`flex flex-col gap-5 mt-5`]}>
                    <div css={[tw`w-52 h-10 bg-gray-300`]}></div>
                    <div css={[tw`flex gap-5`]}>
                      <div css={[tw`w-36 h-10 bg-gray-300 rounded-full`]}></div>
                      <div css={[tw`w-36 h-10 bg-gray-300 rounded-full`]}></div>
                    </div>
                  </div>
                </div>
              </div>
              <div css={[tw`w-auto flex flex-col gap-5`]}>
                <div css={[tw`flex gap-5 justify-center w-full mx-auto`]}>
                  <div css={[tw`w-20 h-8 bg-gray-300`]}></div>
                  <div css={[tw`w-20 h-8 bg-gray-300`]}></div>
                </div>
                <div css={[tw`flex gap-5 w-full`]}>
                  <LoadingOfferCard />
                  <LoadingOfferCard />
                </div>
              </div>
              <div
                css={[
                  tw`w-full flex flex-col justify-center items-center gap-2`,
                ]}
              >
                <div
                  css={[tw`h-4 mb-5 w-32 bg-gray-300 rounded animate-pulse`]}
                ></div>
                <div
                  css={[tw`h-8 w-32 bg-gray-300 rounded animate-pulse`]}
                ></div>
                <div
                  css={[tw`h-8 w-40 bg-gray-300 rounded animate-pulse`]}
                ></div>
              </div>
            </div>
            <div css={[tw`w-full border-b my-10`]}></div>
          </>
        ))}
      </div>
    </div>
  )
}

export default OffersLoadingSkeleton
