import React, { useEffect, useContext, useState } from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"
import { Layout } from "../components/atoms/Layout"
import FeaturedOffers from "../components/organisms/OffersPage/FeaturedOffers/FeaturedOffers"
import OffersTab from "../components/organisms/OffersPage/OffersTab/OffersTab"
import { Family, Offer, Route } from "../global"
import { reduceModelGroups, slugify } from "../helpers"
import { SEO } from "../components/atoms/SEO"
import { HeroCarousel } from "../components/organisms/OffersPage/HeroCarousel"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import useTealiumEvent from "../hooks/Tealium/useTealiumEvent"
import { css } from "@emotion/react"
import { useTealiumContext } from "../contexts/Tealium"
import { LanguageContext } from "../contexts/Language"
import CouponsSection from "../components/molecules/Coupons/CouponSection"
import { Coupon } from "../components/molecules/Coupons/Coupons.d"
import { motion } from "framer-motion"
import { DealersNearYou } from "../components/organisms/DealersNearYou"
import moment from "moment"
import { OffersClient } from "../clients/OffersClient"
import {
  OffersPageProps,
  featuredOffers,
} from "../components/organisms/OffersPage/Offers.d"

/**
 * Offers singleton page with featured offers
 * @author Tyler
 *
 */

const OffersPage: React.FC<OffersPageProps> = ({ data, location }) => {
  const { _ } = useContext(LanguageContext)
  const today = moment()
  const activeCoupons = data?.page?.coupons?.coupons
    ?.map((coupon: Coupon) => {
      const endDate = coupon?.expires && moment(coupon?.expires).endOf("day")
      const startDate = coupon?.starts && moment(coupon?.starts).startOf("day")
      if (today.isBetween(startDate, endDate)) {
        return coupon
      } else {
        return null
      }
    })
    ?.filter(coupon => coupon)

  const { tealPageData } = useTealiumContext()

  // Tealium View and Event functions

  // Events tracked include:
  // Offer refinement (Offers Types ("Featured Offers, Finance Offers..."))
  const { trackTealEvent } = useTealiumEvent()
  const { handleTealView } = useTealiumView()
  // Page data from GraphQL
  const { page } = data

  // Desctructure page data
  const { vehicleFamilies, featuredVehicles, hero, title } = data.page

  // Destructure hero data
  const { carouselItems, _type: heroType } = hero

  // Filtered state, initialized as an array
  const [filtered, setFiltered] = useState([])

  // Offers tab state
  const [selectedTab, setSelectedTab] = useState("Featured")

  // Reset filtered state when new offers tab is selected
  useEffect(() => {
    setFiltered([])
  }, [selectedTab])

  const [featuredOffers, setFeaturedOffers] = useState([])
  const [featuredOffersSeriesData, setFeaturedOffersSeriesData] = useState([])

  // Find the first offer in the above filtered offers that matches user-selected offer type
  const featuredSeriesNames = featuredVehicles.map(
    vehicle => vehicle.series.name
  )
  // Ascertain which series years the offers are for, store unique values and pull top 2
  const seriesYears =
    featuredOffers && Array.isArray(featuredOffers)
      ? [
          ...new Set(
            featuredOffers.map((node: Offer) => node?.series?.currentYear)
          ),
        ].slice(0, 2)
      : []

  useEffect(() => {
    const getFeaturedOffersData = async () => {
      try {
        const featuredOffersData = await OffersClient.getManyOffers({
          series: JSON.stringify(featuredSeriesNames),
          limit: 2,
          type: JSON.stringify([
            "TFS Lease Cash",
            "TFS Finance Cash",
            "APR",
            "Lease",
            "Military",
            "College",
          ]),
          groupBySeries: true,
          groupByYear: true,
        })
        if (featuredOffersData.offers.hasOwnProperty(featuredSeriesNames[0])) {
          setFeaturedOffers(featuredOffersData.offers)
        } else {
          setFeaturedOffers(null)
        }
        if (featuredOffersData.seriesData) {
          setFeaturedOffersSeriesData(featuredOffersData.seriesData)
        }
      } catch (error) {
        console.error("Error fetching offers data", error)
      }
    }
    featuredVehicles && getFeaturedOffersData()
  }, [featuredVehicles])
  /**
   * ~~~~~ FEATURED OFFERS DATA STRUCTURE
   */
  const featuredOffersData =
    featuredOffers !== null
      ? Object.entries(featuredOffers).map(([seriesKey, seriesValue]) => {
          const seriesData: any = Object.entries(seriesValue)
          const seriesSlug = featuredOffersSeriesData?.find(
            s => s.name === seriesKey
          )?.slug
          // Featured image
          const additionalSeriesData = featuredOffersSeriesData.find(
            s => s.name === seriesKey
          )
          let featuredImage: any = null
          for (let i = 0; i < vehicleFamilies.familyRef.length; i++) {
            if (vehicleFamilies.familyRef[i].isActive) {
              featuredImage = vehicleFamilies.familyRef[i].routes?.find(
                (route: Route) => {
                  return route.route.slug.current === slugify(seriesKey)
                }
              )?.seriesImage
              if (featuredImage) {
                break
              }
            }
          }
          featuredImage =
            featuredImage?.image ||
            seriesData?.model?.jellybean?.asset?.gatsbyImageData

          const entries = Object.entries(seriesData[1][1]).sort(
            ([keyA], [keyB]) => Number(keyB) - Number(keyA)
          )

          const [newestYear, newest] = entries[0] || []
          const [secondNewestYear, secondNewest] = entries[1] || []
          return {
            key: seriesValue?.slug,
            image: featuredImage,
            name: seriesKey,
            slug: seriesSlug,
            msrp: additionalSeriesData?.msrp,
            mpgCity: additionalSeriesData?.mpgCity,
            mpgHighway: additionalSeriesData?.mpgHighway,
            offers: {
              newest: Array.isArray(newest) && [...newest],
              newestYear,
              secondNewest:
                entries.length > 1 && Array.isArray(secondNewest)
                  ? [...secondNewest]
                  : null,
              secondNewestYear: entries.length > 1 ? secondNewestYear : null,
              availableOffers: seriesValue.count || 0,
            },
            seriesYears: [newestYear, secondNewestYear],
          } as featuredOffers
        })
      : featuredOffersSeriesData.map((series: any) => {
          const seriesName = series.name
          let featuredImage: any = null
          for (let i = 0; i < vehicleFamilies.familyRef.length; i++) {
            if (vehicleFamilies.familyRef[i].isActive) {
              featuredImage = vehicleFamilies.familyRef[i].routes?.find(
                (route: Route) => {
                  return route.route.slug.current === slugify(seriesName)
                }
              )?.seriesImage
              if (featuredImage) {
                break
              }
            }
          }
          featuredImage = featuredImage?.image
          return {
            key: series?.slug,
            image: featuredImage,
            name: seriesName,
            slug: series?.slug,
            msrp: series?.msrp,
            mpgCity: series?.mpgCity,
            mpgHighway: series?.mpgHighway,
            offers: {
              newest: null,
              newestYear: null,
              secondNewest: null,
              secondNewestYear: null,
              availableOffers: 0,
            },
            seriesYears: null,
          } as featuredOffers
        })
  /**
   * ~~~~~ FINANCE/LEASE/CASH OFFERS DATA STRUCTURE
   */

  const [offersByType, setOffersByType] = useState<{ [key: string]: any }>([])
  const [offersDataType, setOffersDataType] = useState<string>(null)

  useEffect(() => {
    const getOffersData = async () => {
      let offerTypes: string[] = []
      switch (selectedTab) {
        case "APR":
          offerTypes = ["APR", "TFS Finance Cash"]
          break
        case "Lease":
          offerTypes = ["Lease", "TFS Lease Cash"]
          break
        case "Cash":
          offerTypes = ["Military", "College"]
          break
        default:
          offerTypes = [
            "TFS Lease Cash",
            "TFS Finance Cash",
            "APR",
            "Lease",
            "Military",
            "College",
          ]
      }
      try {
        const typeOffersData = await OffersClient.getManyOffers({
          limit: 2,
          type: JSON.stringify(offerTypes),
          groupBySeries: true,
          groupByYear: true,
          groupBySegment: true,
        })
        if (typeOffersData.offers) {
          setOffersByType(typeOffersData.offers)
          setOffersDataType(selectedTab)
        }
      } catch (error) {
        console.error("Error fetching offers data", error)
      }
    }
    selectedTab !== "Featured" && getOffersData()
  }, [selectedTab])

  const offersData =
    offersByType &&
    vehicleFamilies?.familyRef
      ?.filter((family: Family) => family?.isActive === true)
      .map((family: Family) => {
        if (family)
          return {
            family: family?.name,
            data: family?.routes?.map(({ route, seriesImage }: Route) => {
              const vehicle = route?.page?.vehicle

              let modelData: any = reduceModelGroups(vehicle?.models)?.find(
                (model: { isFeatured: boolean }) => model?.isFeatured === true
              )?.model

              // if no featured model is found, return the first model in the array
              // there seems to be some issue with the reduceModelGroups helper
              // if any of the models have featured set to false but another is true it isn't getting the true one
              // this is problematic because it won't actually use the featured one
              // the fix below will make sure that we prevent $Nan from being displayed
              if (modelData === undefined) {
                if (vehicle?.models && vehicle?.models[0]?.models) {
                  // this means we are dealing with a series that exclusively has models in a group
                  modelData = vehicle?.models[0]?.models[0]?.model // TODO clean this up a bit but will work for current use case
                } else {
                  return
                }
              }
              const currentFamilyOffersData = offersByType[family?.name]
                ? offersByType[family?.name]
                : null

              const seriesData =
                currentFamilyOffersData &&
                currentFamilyOffersData[route?.page?.vehicle?.series?.name] &&
                Object.entries(
                  currentFamilyOffersData[route?.page?.vehicle?.series?.name]
                )
              const entries =
                seriesData &&
                Object.entries(seriesData[1][1]).sort(
                  ([keyA], [keyB]) => Number(keyB) - Number(keyA)
                )
              if (entries?.length > 0) {
                const [newestYear, newest] = entries[0] || []
                const [secondNewestYear, secondNewest] = entries[1] || []
                return {
                  key: modelData?._id,
                  image: seriesImage?.image,
                  name: modelData?.series?.name,
                  slug: modelData?.series?.slug,
                  msrp: modelData?.series?.msrp,
                  mpgCity: modelData?.series?.mpgCity,
                  mpgHighway: modelData?.series?.mpgHighway,
                  offers: {
                    newest: [...newest],
                    newestYear: newestYear,
                    secondNewest: entries.length > 1 ? [...secondNewest] : null,
                    secondNewestYear:
                      entries.length > 1 ? secondNewestYear : null,
                    availableOffers:
                      currentFamilyOffersData[
                        route?.page?.vehicle?.series?.name
                      ].count || 0,
                  },
                  seriesYears: [newestYear, secondNewestYear],
                }
              } else if (currentFamilyOffersData) {
                return {
                  key: modelData?._id,
                  image: seriesImage?.image,
                  name: modelData?.series?.name,
                  slug: modelData?.series?.slug,
                  msrp: modelData?.series?.msrp,
                  mpgCity: modelData?.series?.mpgCity,
                  mpgHighway: modelData?.series?.mpgHighway,
                  offers: {
                    newest: null,
                    newestYear: null,
                    secondNewest: null,
                    secondNewestYear: null,
                    availableOffers:
                      currentFamilyOffersData[
                        route?.page?.vehicle?.series?.name
                      ]?.count,
                  },
                  seriesYears: null,
                }
              }
            }),
          }
        else return null
      })

  const offerTabs = [
    { title: _("Featured Offers"), type: "Featured" },
    { title: _("Finance Offers"), type: "APR" },
    { title: _("Lease Offers"), type: "Lease" },
    { title: _("Cash + Special Offers"), type: "Cash" },
    { title: _("Parts & Service Coupons"), type: "Coupons" },
  ]

  useEffect(() => {
    handleTealView({
      page_name: "offers full list",
      tealium_event: "offers_main",
      page_type: "offers",
    })
  }, [])

  /**
   * ~~~~~ MAIN BODY
   *
   * Top row of "... Offers" tabs is generated by mapping over the above "offerTabs"
   * object and passing each item to a Link comp. Link comp onClick changes which offer
   * types are filtered for in "offersData" above, as well as which vehicles/series menu
   * is rendered as the "Featured Offers" tab displays only the specified 5 whereas the
   * remaining three tabs display all families with all corresponding series. The useCallback
   * hook is used to mitigate re-renders.
   */
  const tabAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
     content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const focusAnimation = css`
    &:focus-visible:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
     content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:focus-visible:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const active = css`
    ${tw`font-semibold focus-visible:(border-transparent)`}
    &:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
    &:hover:after {
      ${tw`h-1 bg-red-400`}
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
  `

  return (
    <Layout>
      <SEO
        url={location.href}
        title={title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <section css={[tw`w-full relative z-50`]}>
        <HeroCarousel slides={carouselItems} />
      </section>
      <section
        css={[tw`flex flex-col items-center`]}
        aria-label="Offers Section"
      >
        <nav css={[tw`w-full relative z-10`]} aria-label="Offers Navigation">
          <ul
            css={[
              tw`flex gap-8 w-full px-11 py-10 bg-gray-100 text-center overflow-x-scroll scrollbar-hide lg:justify-center`,
            ]}
          >
            {offerTabs.map(
              (node: { title: string; type: string }, index: number) => {
                return (
                  <li key={node?.type}>
                    <button
                      onClick={() => {
                        setSelectedTab(node?.type)
                        trackTealEvent({
                          tealium_event: "refinement",
                          refinement_type: node?.title || "",
                          refinement_value: `${tealPageData.page_type}|${tealPageData.page_name}|Offer Type|${node?.title}`,
                        })
                      }}
                      css={[
                        tabAnimation,
                        focusAnimation,
                        tw`cursor-pointer whitespace-nowrap inline-block relative py-2 mx-2`,
                        selectedTab === node?.type && active,
                      ]}
                      analytics-id={`offer type selector:refinement:${
                        index + 1
                      }`}
                    >
                      {node?.title}
                    </button>
                  </li>
                )
              }
            )}
          </ul>

          {selectedTab === "Featured" ? (
            <FeaturedOffers
              featuredOffersData={featuredOffersData}
              seriesYears={seriesYears}
              filtered={filtered}
              setFiltered={setFiltered}
            />
          ) : selectedTab === "Coupons" ? (
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,
              }}
              transition={{ duration: 0.75 }}
            >
              <CouponsSection coupons={activeCoupons} />
              <DealersNearYou
                type="default"
                dealerCtaText="Schedule Service"
                dealerCtaLink="scheduleService"
                ctaAnalyticsId="schedule service"
              />
            </motion.div>
          ) : (
            <OffersTab
              offersData={offersData}
              seriesYears={seriesYears}
              filtered={filtered}
              setFiltered={setFiltered}
              selectedTabTitle={selectedTab}
              offersDataType={offersDataType}
            />
          )}
        </nav>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query OffersPageQuery($id: String) {
    page: sanityPageOffers(id: { eq: $id }) {
      ...OfferPageData
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      disclaimers {
        code
        disclaimer
      }
    }
  }
`

export default OffersPage
